<template>
  <q-form ref="editForm">
    <c-card title="상세" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable&&!disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="facility"
            :mappingType="mappingType"
            label="일괄저장" 
            icon="save"
            @beforeAction="save"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            :disabled="disabled"
            :editable="editable"
            :required="true"
            label="시설명(차량번호)"
            name="facilityName"
            v-model="facility.facilityName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :disabled="disabled"
            :editable="editable"
            :required="true"
            label="시설관리번호"
            name="facilityNo"
            v-model="facility.facilityNo">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :disabled="disabled"
            :editable="editable"
            codeGroupCd="HCF_FACILITY_TYPE_CD"
            :required="true"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="hcfFacilityTypeCd"
            label="시설유형"
            v-model="facility.hcfFacilityTypeCd"
            @datachange="facilityTypeChange"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-process
            :disabled="disabled"
            :editable="editable"
            multiple="multiple"
            label="단위공정"
            name="processCd"
            v-model="facility.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :disabled="disabled"
            :editable="editable"
            codeGroupCd="HCF_INSPECTION_CYCLE_CD"
            :required="true"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="hcfInspectionCycleCd"
            label="정기검사주기"
            v-model="facility.hcfInspectionCycleCd"
            @datachange="cycleChange"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :disabled="true"
            :editable="editable"
            label="최종검사일"
            name="lastRemunerationDate"
            v-model="facility.lastRemunerationDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :disabled="true"
            :editable="editable"
            label="차기검사일"
            name="nextInspectionDate"
            v-model="facility.nextInspectionDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="true"
            label="자체점검주기"
            name="selfCheckCycle"
            value="1주">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :disabled="true"
            :editable="editable"
            label="최종자체점검일"
            name="lastSelfCheckDate"
            v-model="facility.lastSelfCheckDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :disabled="true"
            :editable="editable"
            label="차기자체점검일"
            name="nextSelfCheckDate"
            v-model="facility.nextSelfCheckDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant
            :disabled="disabled"
            :required="true"
            :editable="editable"
            type="edit" 
            name="plantCd" 
            v-model="facility.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-radio 
            :disabled="disabled"
            :editable="editable"
            :comboItems="useFlagItems"
            label="사용여부"
            name="useFlag" 
            v-model="facility.useFlag">
          </c-radio>
        </div>
      </template>
    </c-card>
    
    <c-card title="유형별 상세" class="cardClassDetailForm q-mt-sm">
      <template slot="card-description">
        <font class="text-negative" style="font-size:0.8em;font-weight:300;">
          (※ 시설유형에 따라 시설 상세정보를 입력하는 영역입니다.)
        </font>
      </template>
      <template slot="card-detail">
        <template v-if="facilityType1">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-text
              :disabled="disabled"
              :editable="editable"
              :required="true"
              label="설치장소"
              name="installationPlace"
              v-model="facility.installationPlace">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-text
              v-if="facilityType1_1"
              :disabled="disabled"
              :editable="editable"
              type="number"
              label="연간취급량(톤/년)"
              name="annualHandling"
              v-model="facility.annualHandling">
            </c-text>
            <c-text
              v-if="facilityType1_2"
              :disabled="disabled"
              :editable="editable"
              type="number"
              label="시설용량(㎥)"
              name="facilityCapacity"
              v-model="facility.facilityCapacity">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-datepicker
              :disabled="disabled"
              :editable="editable"
              label="설치일"
              name="installationDate"
              v-model="facility.installationDate"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-text
              :disabled="disabled"
              :editable="editable"
              label="방재설비"
              name="disasterPreventionEquipment"
              v-model="facility.disasterPreventionEquipment">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-textarea
              :disabled="disabled"
              :editable="editable"
              label="비고"
              name="remarks"
              v-model="facility.remarks">
            </c-textarea>
          </div>
        </template>
        <template v-if="facilityType2">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-text
              v-if="facilityType2_1"
              :disabled="disabled"
              :editable="editable"
              :required="true"
              label="운송방식"
              name="modeOfTransport"
              v-model="facility.modeOfTransport">
            </c-text>
            <c-text
              v-if="facilityType2_2"
              :disabled="disabled"
              :editable="editable"
              :required="true"
              label="운반방식"
              name="transportMethod"
              v-model="facility.transportMethod">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-text
              v-if="facilityType2_1"
              :disabled="disabled"
              :editable="editable"
              type="number"
              label="탱크 사양(재질 및 용량(㎥, ton)등)"
              name="tankSpecifications"
              v-model="facility.tankSpecifications">
            </c-text>
            <c-text
              v-if="facilityType2_2"
              :disabled="disabled"
              :editable="editable"
              type="number"
              label="운반용량(㎥, ton)"
              name="carryingCapacity"
              v-model="facility.carryingCapacity">
            </c-text>
          </div>
        </template>
        <template v-if="facilityType3">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-text
              :disabled="disabled"
              :editable="editable"
              type="number"
              label="거리(km)"
              name="distance"
              v-model="facility.distance">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-text
              :disabled="disabled"
              :editable="editable"
              label="배관재질"
              name="pipeMaterial"
              v-model="facility.pipeMaterial">
            </c-text>
          </div>
        </template>
      </template>
    </c-card>
    <c-table
      ref="table"
      class="q-mt-sm"
      title="취급 유해화학자재 목록"
      tableId="facility"
      :editable="editable&&!disabled"
      :columns="grid.columns"
      :data="facility.hazardChems"
      :merge="grid.merge"
      gridHeight="400px"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      selection="multiple"
      rowKey="psiHazardMaterialId"
    >
      <!-- 버튼 영역 -->
      <!-- <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" 
            :showLoading="false" 
            label="추가" 
            icon="add" 
            @btnClicked="addHazardChem" />
          <c-btn 
            v-if="editable&&!disabled" 
            :disabled="!facility.hazardChems || facility.hazardChems.length === 0"
            label="제외" 
            icon="remove" 
            @btnClicked="deleteHazardChem" />
        </q-btn-group>
      </template> -->
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazard-facility-detail',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          facilityCd: '',
          search: false,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      facility: {
        plantCd: '',  // 사업장코드
        facilityCd: '',  // 시설 코드
        facilityNo: '', // 시설 관리번호
        facilityName: '',  // 시설명
        processCd: '',  // 공정코드
        hcfFacilityTypeCd: null,  // 시설유형 코드
        hcfInspectionCycleCd: null,  // 정기검사주기 코드
        lastRemunerationDate: '',  // 최종보수일
        nextInspectionDate: '',  // 차기검사일
        lastSelfCheckDate: '',  // 최종자체점검일
        nextSelfCheckDate: '',  // 차기자체점검일

        installationPlace: '',  // 설치장소
        annualHandling: null,  // 연간취급량(톤/년)
        facilityCapacity: null,  // 시설용량(㎥)
        installationDate: '',  // 설치일
        disasterPreventionEquipment: '',  // 방재설비
        remarks: '',  // 비고
        modeOfTransport: '',  // 운송방식
        transportMethod: '',  // 운반방식
        tankSpecifications: null,  // 탱크 사양(재질 및 용량(㎥, ton)등)
        carryingCapacity: null,  // 운반용량(㎥, ton)
        distance: null,  // 거리(km)
        pipeMaterial: '',  // 배관재질

        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        hazardChems: [], // 빈도
        deleteHazardChems: [], // [삭제] 빈도
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      grid: {
        // merge: [
        //   { index: 0, colName: 'materialName' },
        //   { index: 1, colName: 'materialName' },
        //   { index: 5, colName: 'materialName' },
        //   { index: 6, colName: 'materialName' },
        //   { index: 7, colName: 'materialName' },
        //   { index: 8, colName: 'materialName' },
        //   { index: 9, colName: 'materialName' },
        //   { index: 10, colName: 'materialName' },
        //   { index: 11, colName: 'materialName' },
        //   { index: 12, colName: 'materialName' },
        //   { index: 13, colName: 'materialName' },
        //   { index: 14, colName: 'materialName' },
        //   { index: 15, colName: 'materialName' },
        //   { index: 16, colName: 'materialName' },
        // ],
        columns: [
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          // {
          //   name: 'chemNmKr',
          //   field: 'chemNmKr',
          //   label: '화학물질명',
          //   align: 'left',
          //   style: 'width:200px',
          //   type: 'link',
          //   sortable: true,
          // },
          // {
          //   name: 'casNo',
          //   field: 'casNo',
          //   label: 'CAS No.',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
          // {
          //   name: 'molecularFormula',
          //   field: 'molecularFormula',
          //   label: '분자식',
          //   align: 'center',
          //   style: 'width:120px',
          //   sortable: true,
          // },
          {
            label: '폭발한계(%)',
            align: 'center',
            child: [
              {
                name: 'explUpLimit',
                field: 'explUpLimit',
                label: '상한',
                align: 'center',
                style: 'width:70px',
                sortable: false
              },
              {
                name: 'explLowLimit',
                field: 'explLowLimit',
                label: '하한',
                align: 'center',
                style: 'width:70px',
                sortable: false
              },
            ]
          },
          {
            name: 'exposureLimit',
            field: 'exposureLimit',
            label: '노출기준',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'toxicValue',
            field: 'toxicValue',
            label: '독성치',
            align: 'center',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'flashingPoint',
            field: 'flashingPoint',
            label: '인화점',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'ignitionPoint',
            field: 'ignitionPoint',
            label: '발화점',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'vaporPressure',
            field: 'vaporPressure',
            label: '증기압',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'corrosivenessFlag',
            field: 'corrosivenessFlag',
            label: '부식성유무',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'extraReaction',
            field: 'extraReaction',
            label: '이상반응유무',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'dailyUsage',
            field: 'dailyUsage',
            label: '일일취급량',
            align: 'center',
            style: 'width:100px',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'stockage',
            field: 'stockage',
            label: '저장량',
            align: 'center',
            style: 'width:100px',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      detailUrl: '',
      saveUrl: '',
      isSave: false,
      mappingType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    facilityType1() {
      // 제조.사용시설, 유해화학물질 저장시설, 유해화학물질 보관시설
      return ['HFT0000001', 'HFT0000005', 'HFT0000010', 'HFT0000015', 'HFT0000020', 'HFT0000025'].indexOf(this.facility.hcfFacilityTypeCd) > -1
    },
    facilityType1_1() {
      // 제조.사용시설
      return ['HFT0000001'].indexOf(this.facility.hcfFacilityTypeCd) > -1
    },
    facilityType1_2() {
      // 제조.사용시설, 유해화학물질 저장시설, 유해화학물질 보관시설
      return ['HFT0000005', 'HFT0000010', 'HFT0000015', 'HFT0000020', 'HFT0000025'].indexOf(this.facility.hcfFacilityTypeCd) > -1
    },
    facilityType2() {
      // 차량 운송시설 및 설비, 차량 운반시설 및 설비
      return ['HFT0000030', 'HFT0000035'].indexOf(this.facility.hcfFacilityTypeCd) > -1
    },
    facilityType2_1() {
      // 차량 운송시설 및 설비, 차량 운반시설 및 설비
      return ['HFT0000030'].indexOf(this.facility.hcfFacilityTypeCd) > -1
    },
    facilityType2_2() {
      // 차량 운송시설 및 설비, 차량 운반시설 및 설비
      return ['HFT0000035'].indexOf(this.facility.hcfFacilityTypeCd) > -1
    },
    facilityType3() {
      // 배관이송
      return ['HFT0000040'].indexOf(this.facility.hcfFacilityTypeCd) > -1
    },
    disabled() {
      // 배관이송
      return this.popupParam.search
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.env.facility.get.url
      this.saveUrl = transactionConfig.env.facility.insert.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.facilityCd) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.facilityCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.facility, _result.data);
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      }
    },
    facilityTypeChange() {
      /**
       * 유형에 따라 입력해야하는 값이 변함으로
       * 유형이 변경될때 값을 리셋하는 작업이 필요
       */
      let resetData = {
        installationPlace: '',  // 설치장소
        annualHandling: null,  // 연간취급량(톤/년)
        facilityCapacity: null,  // 시설용량(㎥)
        installationDate: '',  // 설치일
        // lastRemunerationDate: '',  // 최종보수일
        disasterPreventionEquipment: '',  // 방재설비
        remarks: '',  // 비고
        modeOfTransport: '',  // 운송방식
        transportMethod: '',  // 운반방식
        tankSpecifications: null,  // 탱크 사양(재질 및 용량(㎥, ton)등)
        carryingCapacity: null,  // 운반용량(㎥, ton)
        distance: null,  // 거리(km)
        pipeMaterial: '',  // 배관재질
      }
      this.$_.extend(this.facility, resetData);
    },
    cycleChange(data) {
      if (['HIC0000001', 'HIC0000005'].indexOf(data.value) > -1 && this.facility.lastRemunerationDate) {
        if (data.value === 'HIC0000001') {
          // 1 year
          this.$set(this.facility, 'nextInspectionDate', 
            this.$comm.getCalculatedDate(
              this.facility.lastRemunerationDate,
              '1y',
              'YYYY-MM-DD',
              'YYYY-MM-DD'
            )
          );
        } else {
          // 2 year
          this.$set(this.facility, 'nextInspectionDate', 
            this.$comm.getCalculatedDate(
              this.facility.lastRemunerationDate,
              '2y',
              'YYYY-MM-DD',
              'YYYY-MM-DD'
            )
          );
        }
      } else {
        this.$set(this.facility, 'nextInspectionDate', null);
      }
    },
    // linkClick(row, col) {
    //   let tabname = '';
    //   if (col.name == 'materialName') {
    //     tabname = 'chemPsm';
    //   } else if (col.name == 'chemNmKr') {
    //     tabname = 'chemMsds';
    //   }
    //   this.popupOptions.title = '화학자재 상세';
    //   this.popupOptions.param = {
    //     mdmChemMaterialId: row ? row.mdmChemMaterialId : '',
    //     plantCd: row ? row.plantCd : '',
    //     tabName: tabname,
    //   };
    //   this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chemDetail.vue'}`);
    //   this.popupOptions.visible = true;
    //   this.popupOptions.isFull = true;
    //   this.popupOptions.closeCallback = this.closePopup;
    // },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    /* eslint-disable no-unused-vars */
    // addHazardChem() {
    //   this.popupOptions.title = '유해화학자재';
    //   this.popupOptions.param = {
    //     type: 'multiple',
    //     plantCd: this.facility.plantCd,
    //   };
    //   this.popupOptions.target = () => import(`${'@/pages/psi/hsl/hslManagePop.vue'}`);
    //   this.popupOptions.visible = true;
    //   this.popupOptions.isFull = false;
    //   this.popupOptions.width = '75%';
    //   this.popupOptions.closeCallback = this.closeHazardChemPopup;
    // },
    closeHazardChemPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.facility.hazardChems, { psiHazardMaterialId: item.psiHazardMaterialId }) === -1) {
            this.$set(item, 'facilityCd', this.facility.facilityCd)
            this.$set(item, 'editFlag', 'C')
            this.$set(item, 'regUserId', this.$store.getters.user.userId)
            this.facility.hazardChems.splice(0, 0, item)
          }
        })
      }
    },
    deleteHazardChem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.facility.deleteHazardChems) this.facility.deleteHazardChems = [];
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.facility.deleteHazardChems, { psiHazardMaterialId: item.psiHazardMaterialId }) === -1
            && item.editFlag !== 'C') {
            this.facility.deleteHazardChems.push(item)
          }
          this.facility.hazardChems = this.$_.reject(this.facility.hazardChems, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    save() {
      if (this.popupParam.facilityCd) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.env.facility.update.url
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.env.facility.insert.url
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.facility.regUserId = this.$store.getters.user.userId
              this.facility.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.facilityCd = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>